import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Login from "pages/Login";
import Register from "pages/Register";
import LostForgot from "pages/LostForgot";
import Forgot from "pages/Forgot";
import Layout from "pages/layout";
import ModifyCompany from "pages/modifyCompany";
import ModifyTempWorker from "pages/modifyTempWorker";


const ConnectionRoutes = () => {
    return (
        <>
            <Routes>
                {/*Route public*/}
                <Route path='/' element={<Layout/>}>
                    {/*<Route element={<PublicRoutes/>}>*/}
                    <Route path='login/interimaire' element={<Login/>}/>
                    <Route path='login/entreprise' element={<Login/>}/>
                    <Route path="register/interimaire" element={<Register/>}/>
                    <Route path="forgot/entreprise" element={<Forgot/>}/>
                    <Route path="lostForgot/entreprise" element={<LostForgot/>}/>
                    <Route path="forgot/interimaire" element={<Forgot/>}/>
                    <Route path="lostForgot/interimaire" element={<LostForgot/>}/>
                    <Route path={"entreprise/invitation"} element={<ModifyCompany/>}/>
                    <Route path={"interimaire/invitation"} element={<ModifyTempWorker/>}/>
                    <Route path={""} element={<Navigate to="/login/interimaire"/>} />
                    <Route path={"*"} element={<Navigate to="/login/interimaire"/>} />
                    {/*</Route>*/}
                </Route>
            </Routes>
        </>
    )
        ;
};

export default ConnectionRoutes;