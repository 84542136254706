import React, {useState, useEffect, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom'

import ApiDatabase from "server"

//Import Component
import NavbarAuth from "components/navBar";
import useModal from "components/modal/useModal";
import {Information, Mail, NoShowPassword, ShowPassword} from "assets/icons";
import Button from "components/button";
import UseContext from "hooks/useContext";
import useAuth from "hooks/useAuth";
import axios from "axios";
import {ToastContainer} from "react-toastify";

const EMAIL_REGEX = /^[\w-.+]+@[a-zA-Z\d-]+(\.[a-zA-Z\d-]+)*\.[a-zA-Z]{2,}$/;

const Login = () => {

    const userRef = useRef();
    const {updateUser,typeNav,email,setEmail}=UseContext()
    const {setAuth}=useAuth()
    const navigate = useNavigate();
    const [validEmail, setValidEmail] = useState(false);
    const [password, setPassword] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const {toggle} = useModal()
    const [valid, setValid] = useState(false)
    // State show Password
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => setShowPassword(!showPassword);
    const location = useLocation();

    //get multiple get params in url
    const params = new URLSearchParams(location.search);
    let redirectURL = params.get('redirect');
    let redirectURL2 = '';

    useEffect(() => {
        setErrMsg("");
    }, [email, password]);
    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email))
    }, [email])

    const handleUserInput = (e) => setEmail(e.target.value);

    const handlePasswordInput = (e) => setPassword(e.target.value);

    const handleForgot = () => {
        if(typeNav ===2 )navigate('/forgot/entreprise')
        else navigate('/forgot/interimaire')
    }

    const handleSubmit =  async (e) => {
        e.preventDefault();
        setValid(false)

        if (!email || !password) {
            setErrMsg("Veuillez remplir tous les champs");
            return;
        }

        if (!validEmail) {
            setErrMsg("Format de l'email incorrect");
            return;
        }

        let ip = null
        let browser = null
        let os = null
        let device = null
        let country = null
        let city = null
        let region = null
        let latitude = null
        let longitude = null
        let timezone = null
        //get ip address and other info with https://ipinfo.io/json
        const ipInfos = await axios.get(`https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`).then(res => res.data).catch(err => null)

        if(ipInfos){
            ip = ipInfos.ip
            country = ipInfos.country
            city = ipInfos.city
            region = ipInfos.region
            latitude = ipInfos.loc.split(',')[0]
            longitude = ipInfos.loc.split(',')[1]
            timezone = ipInfos.timezone
        }

        //get browser, os, device
        let OSName = "Unknown OS";
        if (navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
        if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
        if (navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
        if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux";
        if (navigator.appVersion.indexOf("Android") !== -1) OSName = "Android";
        if (navigator.appVersion.indexOf("like Mac") !== -1) OSName = "iOS";

        os = OSName

        let userAgent = navigator?.userAgent

        if(userAgent){
            //return browser name and version
            if (userAgent.indexOf("Chrome") !== -1) browser = "Chrome";
            else if (userAgent.indexOf("Safari") !== -1) browser = "Safari";
            else if (userAgent.indexOf("Firefox") !== -1) browser = "Firefox";
            else if (userAgent.indexOf("MSIE") !== -1) browser = "IE";
            else browser = "Unknown Browser";

            //return device name and type
            if (userAgent.indexOf("Mobile") !== -1) device = "Mobile";
            else if (userAgent.indexOf("Tablet") !== -1) device = "Tablet";
            else device = "Desktop";
        }

        ApiDatabase.postUserLogin({
            email,
            password,
            ip,
            browser,
            os,
            device,
            country,
            city,
            region,
            latitude,
            longitude,
            timezone
        }, async (data) => {
            const isAdminConnected = !!data.user?.isAdminConnected;

            if (data.user?.validation === 0 && !isAdminConnected) {
                updateUser(data.xsrfToken, data.user.role)
                toggle('tok_u_token');
            } else {
                updateUser(data.xsrfToken, data.user.role)
                setAuth({roles: data.user.role, user: email})
                if (data.user.role === 100) {
                    if((OSName === 'Android' || OSName === 'iOS') && !isAdminConnected && data.user.isMobileSuggestion){
                        //redirectURL2 = redirectURL with encodeURIComponent
                        if(redirectURL) redirectURL2 = encodeURIComponent(redirectURL)
                        redirectURL = '%2FmobileSuggestion'
                    }
                    window.location.assign(`${process.env.REACT_APP_TOKEN_INTERIM}${data.xsrfToken}${redirectURL ? '&redirect=' + redirectURL : ''}${redirectURL2 ? '&redirect2=' + redirectURL2 : ''}`)
                } else if (data.user.role >= 200) {
                    window.location.assign(`${process.env.REACT_APP_TOKEN_COMPANY}${data.xsrfToken}${redirectURL ? '&redirect=' + redirectURL : ''}`)
                }
            }
        }, (err) => {
            setValid(true)
            console.log(err)
        })
    }

    return (
        <>
            <div className="flex h-screen overflow-hidden">
                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {/* Navbar */}
                    <NavbarAuth></NavbarAuth>
                    <main className="h-screen flex items-center justify-center">
                        {/* Main Content */}
                        <div
                            className=" flex items-center justify-center py-12 px-5 mm:px-6 lg:px-8">
                            <div className="max-w-md w-full space-y-8">
                                <div>
                                    <h2 className="mt-6 text-center text-4xl font-medium text-[#111827]">
                                        Se connecter
                                    </h2>

                                </div>
                                {valid || errMsg ?
                                    <p className='text-red-500 flex flex-row gap-4 items-center justify-center'>
                                        <span>
                                              <Information wh={15} color={'#EF4444'}/>
                                         </span>
                                        {errMsg ? errMsg : "Votre email ou mot de passe est incorrect"}
                                    </p> : ''
                                }
                                <div
                                    className="rounded bg-white md:max-w-tab tab:w-[30rem] overflow-hidden  p-2 mm:p-5 ">
                                    <form className="space-y-4">
                                        <div className="rounded-md shadow-sm -space-y-px">
                                            <div className="grid grid-cols-12 gap-6">
                                                <div className="col-span-12">
                                                    <label
                                                        htmlFor="email_address"
                                                        className="block text-sm font-medium font-Poppins text-gray-500"
                                                    >
                                                        E-mail
                                                    </label>
                                                    <div className="relative text-gray-500">
                                                        <input
                                                            name="email"
                                                            ref={userRef}
                                                            value={email}
                                                            onChange={handleUserInput}
                                                            className="w-full h-10 pl-8 pr-3 text-base placeholder-gray-600 border rounded-lg "
                                                            type="email"
                                                            required
                                                        />
                                                        <div
                                                            className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                                            <Mail wh={18} color={'#666666'}
                                                                  strokeW={2}/>

                                                        </div>
                                                    </div>
                                                    <p
                                                        className={
                                                            email && !validEmail
                                                                ? "text-[#FF9600] flex flex-row gap-4 items-center"
                                                                : "hidden"
                                                        }
                                                    >
                                                          <span>
                                                                <Information wh={20}
                                                                             color={'#FF9600'}/>
                                                          </span>
                                                        Format de l'email incorrect
                                                    </p>
                                                </div>
                                                <div className="col-span-12">
                                                    <label
                                                        className="block ft-sm font-medium font-Poppins text-gray-500">
                                                        Mot de passe
                                                    </label>
                                                    <div className="relative text-[#6B7280]">
                                                        <input
                                                            name="password"
                                                            value={password}
                                                            onChange={handlePasswordInput}
                                                            className={`w-full h-10 pl-3 pr-8 text-base border rounded-lg  `}
                                                            type={showPassword ? "text" : "password"}
                                                            required
                                                        />
                                                        <div
                                                            className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer"
                                                            onClick={handleShowPassword}
                                                        >
                                                            {showPassword ? (
                                                                <NoShowPassword wh={18}
                                                                                color={'#666666'}
                                                                                strokeW={2}/>
                                                            ) : (
                                                                <ShowPassword wh={18}
                                                                              color={'#666666'}
                                                                              strokeW={2}/>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="flex flex-wrap justify-center tab:justify-between items-center gap-2">
                                                <span
                                                    className="text-sm font-bold font-Poppins text-gray-500 cursor-pointer"
                                                    onClick={handleForgot}>
                                                  Mot de passe oublié ?
                                                </span>
                                            <Button size={'LG'} onClick={handleSubmit}>
                                                Se connecter
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <ToastContainer
                    position="bottom-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    limit={2}
                    pauseOnFocusLoss
                    draggable
                />
            </div>
        </>
    );
};

export default Login;
