import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";

// import components
import Modalheader from "components/modal/components/header";
import ModalBody from "components/modal/components/body";
import ModalFooter from "components/modal/components/footer";
import axios from "axios";
import useModal from "components/modal/useModal";
import Button from "components/button";
import UseContext from "hooks/useContext";

const ModalToken = () => {
    const {email}=UseContext()
    const [token, setToken] = useState('')
    const {toggle}=useModal()
    const [validToken, setValidToken] = useState(false)
    const [waitValidationCode, setWaitValidationCode] = useState(0)
    const navigate = useNavigate()

    const handleMail = async (e) => {
        e.preventDefault()
        axios({
            method:'post',
            url:`${process.env.REACT_APP_API_URL}resend/tokenMail`,
            data:{
                email
            }
        })
            .then((res)=>{
                setWaitValidationCode(30)
            })
            .catch((err)=>{
                if (err?.response?.data?.timeout) setWaitValidationCode(err?.response?.data?.timeout)
            })

    }

    useEffect(() => {
        //timeout for resend mail when the time is waitValidationCode
        if (waitValidationCode > 0) {
            setTimeout(() => {
                setWaitValidationCode(waitValidationCode - 1)
            }, 1000)
        }
    }, [waitValidationCode]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}resend/token`,
            data: {
                email,
                token
            }
        })
            .then((res) => {
                if (res.data.error) {
                    setValidToken(true)
                } else {
                    setValidToken(false)
                    navigate('/login/interimaire')
                    toggle()
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }
    return (
        <>
            <Modalheader hide={toggle} hideShow={true}>
                Validation du code d'inscription
            </Modalheader>
            {/*Body*/}
            <ModalBody>
                <form className='flex flex-col w-full'>
                    <label htmlFor='token'>Vérification par mail</label>
                    <p className={ validToken ?
                        "text-red-500 flex flex-row gap-4 items-center"
                        : 'hidden'
                    }>
                        <span>
                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                                 <path
                                                     d="M7.00004 13.6667C3.31804 13.6667 0.333374 10.682 0.333374 7C0.333374 3.318 3.31804 0.333336 7.00004 0.333336C10.682 0.333336 13.6667 3.318 13.6667 7C13.6667 10.682 10.682 13.6667 7.00004 13.6667ZM6.33337 9V10.3333H7.66671V9H6.33337ZM6.33337 3.66667V7.66667H7.66671V3.66667H6.33337Z"
                                                     fill="#FF9600"
                                                 />
                            </svg>
                        </span>
                        Veuillez indiquer un token valide.
                    </p>
                    <input
                        className='w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg '
                        name='token'
                        onChange={(e) => setToken(e.target.value)}
                        value={token}
                    />
                </form>
                <div className='flex justify-center items-center mt-4 ft-xs'>
                    {waitValidationCode > 0 ? (
                        <>
                            <div className={'text-gray-500 flex flex-col gap-1 text-center'}>
                                <div>Renvoyer le code de vérification dans {waitValidationCode} secondes.</div>
                                <div>Pensez à vérifier vos spams/indésirables.</div>
                            </div>
                        </>
                    ) : (
                        <div className={'cursor-pointer'} onClick={handleMail}>Renvoyer le code de vérification</div>
                    )}
                </div>
            </ModalBody>
            <ModalFooter >
                <Button onClick={handleSubmit} size={'LG'}>Confirmer</Button>
            </ModalFooter>
        </>
    );
};


export default ModalToken;
