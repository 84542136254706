import React, {useState, useContext, useEffect} from 'react'

import DatePicker,{registerLocale} from "react-datepicker";

import { enGB,fr } from "date-fns/locale";
registerLocale('en-GB',enGB)
registerLocale('fr-FR',fr)

const BirthdayDatePicker = ({dateSelect, setDateSelect}) => {
    const [startDate, setStartDate] = useState('');

    useEffect(() => {
        setDateSelect(startDate)
    }, [startDate, setDateSelect])

    useEffect(() => {
        if (dateSelect && dateSelect !== startDate) {
            setStartDate(dateSelect)
        }
    }, [dateSelect]);

    return (
        <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            locale='fr-FR'
            peekNextMonth
            disabledKeyboardNavigation
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            popperPlacement="bottom"
            className='w-full h-10 pl-3 pr-8 ft-b border rounded-lg '
        />)
}

export default BirthdayDatePicker