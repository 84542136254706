import {useEffect, useRef, useState} from "react";
import ApiDatabase from 'server'

const AddressInfos = ({userData, setUserData, setValidFormAddress}) => {
    const [address, setAddress] = useState('')
    const [searchAddress, setSearchAddress] = useState('')
    const [addressList, setAddressList] = useState([])
    const [addressListChoice, setAddressListChoice] = useState([])
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const dropDownRef = useRef(null)

    useEffect(() => {
        ApiDatabase.getCities({}, (data) => {
            setAddressList(data)
        }, (err) => {
            console.log(err)
        })
    }, [])

    useEffect(() => {
        //fuzzy search on addressList with searchAddress
        const addressChoices = []
        for (let i = 0; i < addressList.length; i++) {
            //compare searchAddress with addressList[i].departement and addressList[i].name, escape special characters and accents and case insensitive and spaces and uppercase
            //replace œ by oe, replace éèêë by e, replace àâä by a, replace ùûü by u, replace îï by i, replace ôö by o, replace ç by c, replace characters other than letters by nothing
            const regex = new RegExp(searchAddress.replace(/œ/g, 'oe').replace(/[éèêë]/g, 'e').replace(/[àâä]/g, 'a').replace(/[ùûü]/g, 'u').replace(/[îï]/g, 'i').replace(/[ôö]/g, 'o').replace(/[ç]/g, 'c').replace(/[^a-zA-Z]/g, ''), 'i')
            if (regex.test(addressList[i].name.replace(/œ/g, 'oe').replace(/[éèêë]/g, 'e').replace(/[àâä]/g, 'a').replace(/[ùûü]/g, 'u').replace(/[îï]/g, 'i').replace(/[ôö]/g, 'o').replace(/[ç]/g, 'c').replace(/[^a-zA-Z]/g, ''))) {
                addressChoices.push(addressList[i])
            }
        }
        setAddressListChoice(addressChoices)
        if (searchAddress && searchAddress !== (userData.address?.city + ', ' + userData.address?.department)) {
            setUserData({...userData, address: {city: null, department: null}})
            setAddress('')
            setValidFormAddress(false)
        }
    }, [searchAddress, dropdownOpen])

    const selectAddress = (address) => {
        setSearchAddress(`${address.name}, ${address.departement}`)
        setAddressListChoice([])
        setUserData({...userData, address: {city: address.name, department: address.departement}})
        setAddress(address)
        setValidFormAddress(true)
    }

    useEffect(() => {
        if (userData.address) {
            if (userData.address.city && userData.address.department) {
                setSearchAddress(`${userData.address.city}, ${userData.address.department}`)
                setAddressListChoice([])
                setAddress(userData.address)
                setValidFormAddress(true)
            }
        }
    }, [userData.address])

    useEffect(() => {
        let handler = (e) => {
            if (!dropDownRef.current.contains(e.target)) {
                setDropdownOpen(false)
            } else {
                setDropdownOpen(true)
            }
        }
        document.addEventListener("mousedown", handler)

        return () => {
            document.removeEventListener("mousedown", handler)
        }
    }, [])

    return (
        <div className="rounded-md -space-y-px">
            <div className="flex flex-col">
                <label className="block ft-sm font-medium text-gray-500">
                    Ville <span className={'text-red-500'}>*</span>
                </label>
                <div className="relative text-gray-500" ref={dropDownRef}>
                    <input
                        name="address"
                        value={searchAddress}
                        onChange={(e) => setSearchAddress(e.target.value)}
                        className={`w-full h-10 pl-3 pr-8 ft-b border rounded-lg`}
                        type={'text'}
                        placeholder={'Rechercher une ville ...'}
                        required
                    />
                    {(dropdownOpen && !userData.address.city && !userData.address.department) && (
                        <>
                            {addressListChoice.length > 0 ? (
                                <div className={'absolute top-11 left-0 w-full max-h-40 overflow-y-auto'}>
                                    <div className={'bg-white border rounded'}>
                                        {addressListChoice.map((address, index) => (
                                            <>
                                                <div key={index} className={`flex flex-col gap-2 p-2 ${addressListChoice.length -1 !== index && 'border-b'}`}>
                                                    <button className={'flex flex-row gap-2 items-center'} onClick={(e) => {
                                                        e.preventDefault()
                                                        selectAddress(address)
                                                    }}>
                                                        <span className={'ft-sm'}>{address.name}, {address.departement}</span>
                                                    </button>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className={'absolute top-11 left-0 w-full max-h-40 overflow-y-auto'}>
                                    <div className={'bg-white border rounded'}>
                                        <div className={`flex flex-col gap-2 p-2`}>
                                            <p className={'ft-sm'}>Aucun résultat</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default AddressInfos;