import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {EMAIL_REGEX,checkPassword} from 'helper/helper'
import {
    Information,
    InterrogationCercle,
    ValidationCercle,
    Mail,
    NoShowPassword,
    ShowPassword
} from "assets/icons";

const Register = ({userData, setUserData, setValidFormRegister}) => {
    const navigate = useNavigate();

    // State verify
    const [validEmail, setValidEmail] = useState(false);
    const [validMatch, setValidMatch] = useState(false);
    const [lower, setLower] = useState(false)
    const [upper, setUpper] = useState(false)
    const [number, setNumber] = useState(false)
    const [special, setSpecial] = useState(false)
    const [length, setLength] = useState(false)
    const [valid, setValid] = useState(false)

    const handleCheck = () => {
        const result = checkPassword(userData.password)
        setLower(result.lower)
        setNumber(result.number)
        setLength(result.length)
        setUpper(result.upper)
        setSpecial(result.special)
        if (length && special && number && upper && lower) setValid(true)
        else setValid(false)
    }

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(userData.email));
    }, [userData.email]);
    useEffect(() => {
        setValidMatch(userData.password === userData.confirmPassword);
        if (validMatch) {

        }
    }, [userData.password, userData.confirmPassword]);

    // State show Password
    const [showPassword, setShowPassword] = useState(false);
    const [confirmShowPassword, setConfirmShowPassword] = useState(false);
    const handleShowPassword = () => setShowPassword(!showPassword);
    const handleConfirmShowPassword = () =>
        setConfirmShowPassword(!confirmShowPassword);


    if (validMatch && validEmail) {
        setValidFormRegister(true);
    }

    useEffect(() => {
        handleCheck()
    }, [])

    useEffect(() => {
        if (length && special && number && upper && lower) setValid(true)
        else setValid(false)
    }, [length, special, number, upper, lower]);

    return (
        <>
            <div className="rounded-md shadow-sm -space-y-px">
                <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-12">
                        <label className="block">
                            <div className={'ft-sm font-medium  text-gray-500'}></div>
                            E-mail <span className={'text-red-500'}>*</span>
                            <div className="relative text-gray-500">
                                <input
                                    name="email"
                                    value={userData.email}
                                    onChange={(e) => {
                                        setUserData({...userData, email: e.target.value});
                                    }}
                                    className="w-full h-10 pl-8 pr-3 ft-b placeholder-gray-600 border rounded-lg "
                                    type="email"
                                    required
                                />
                                <div
                                    className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                    <Mail wh={18} color={'#666666'} strokeW={2}/>
                                </div>
                            </div>
                        </label>
                        <p
                            className={
                                userData.email && !validEmail
                                    ? "text-yellow-500 flex flex-row gap-4 items-center"
                                    : "hidden"
                            }
                        >
                              <span>
                                  <Information wh={15} color={'#FF9600'}/>
                              </span>
                            Format de l'email incorrect
                        </p>
                    </div>
                    <div className="col-span-12">
                        <label className="block">
                            <div className={'ft-sm font-medium text-gray-500'}>
                                Mot de passe <span className={'text-red-500'}>*</span>
                            </div>
                            <div className="relative text-gray-500">
                                <input
                                    name="password"
                                    value={userData.password}
                                    onChange={(e) => {
                                        setUserData({...userData, password: e.target.value});
                                    }}
                                    onKeyUp={handleCheck}
                                    className={`w-full h-10 pl-3 pr-8 ft-b border rounded-lg  `}
                                    type={showPassword ? "text" : "password"}
                                    required
                                />
                                <div
                                    className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer"
                                    onClick={handleShowPassword}
                                >
                                    {showPassword ? (
                                        <NoShowPassword wh={18} color={'#666666'} strokeW={2}/>
                                    ) : (
                                        <ShowPassword wh={18} color={'#666666'} strokeW={2}/>
                                    )}
                                </div>
                            </div>
                        </label>
                        {userData.password !== '' ?
                            <div className='bg-gray-100 p-2 sh-sm mt-3 rounded-md'>
                                <p className={`flex flex-row justify-start items-center gap-3 ${valid ? 'text-green-600':'text-yellow-600'}`}>
                                  <span>
                                      {valid ?
                                          <Information wh={20} color={'#059669'}/> :

                                          <Information wh={20} color={'#FF9600'}/>
                                      }
                                  </span>
                                    Votre mot de passe doit contenir :
                                </p>
                                <ul className="relative flex flex-col gap-3 mt-2">
                                    <li className={`${length ? 'text-gray-300' : ''} relative flex flex-row items-center list-none transition-all ft-sm gap-3 `}>
                                        {/*#059669 -> vert 600 #DC2626-> red 600 */}
                                        {length ?

                                            <ValidationCercle wh={20} color={'#059669'}/> :
                                            <InterrogationCercle wh={20} color={'#DC2626'}/>
                                        }Au moins 8 caractères {userData.password?.length > 40 ? '(40 max)' : ''}
                                    </li>
                                    <li className={`${upper ? 'text-gray-300' : ''} relative flex flex-row list-none transition-all ft-sm gap-3`}>
                                        {upper ?

                                            <ValidationCercle wh={20} color={'#059669'}/> :
                                            <InterrogationCercle wh={20} color={'#DC2626'}/>
                                        }
                                        Au moins un caractère majuscule
                                    </li>
                                    <li className={`${lower ? 'text-gray-300' : ''} relative flex flex-row list-none transition-all ft-sm gap-3`}>
                                        {lower ?

                                            <ValidationCercle wh={20} color={'#059669'}/> :
                                            <InterrogationCercle wh={20} color={'#DC2626'}/>
                                        }
                                        Au moins un caractère minuscule
                                    </li>
                                    <li className={`${special ? 'text-gray-300' : ''} relative flex flex-row list-none transition-all ft-sm gap-3`}>
                                        {special ?

                                            <ValidationCercle wh={20} color={'#059669'}/> :
                                            <InterrogationCercle wh={20} color={'#DC2626'}/>
                                        }
                                        Au moins un caractère spécial
                                    </li>
                                    <li className={`${special ? 'text-gray-300' : ''} relative flex flex-row list-none transition-all ft-sm gap-3`}>
                                        {number ?

                                            <ValidationCercle wh={20} color={'#059669'}/> :
                                            <InterrogationCercle wh={20} color={'#DC2626'}/>
                                        }
                                        Au moins un chiffre (0-9)
                                    </li>
                                </ul>
                            </div>
                            : ''}
                    </div>
                    <div className="col-span-12">
                        <label className="block">
                            <div className={'ft-sm font-medium text-gray-500'}>
                                Confirmation mot de passe <span className={'text-red-500'}>*</span>
                            </div>
                            <div className="relative">
                                <input
                                    name="confirmPassword"
                                    onChange={(e) => {
                                        setUserData({...userData, confirmPassword: e.target.value});
                                    }}
                                    value={userData.confirmPassword}
                                    className="w-full h-10 pl-3 pr-8 ft-b border rounded-lg focus:shadow-outline "
                                    type={confirmShowPassword ? "text" : "password"}
                                    required
                                />
                                <div
                                    className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer"
                                    onClick={handleConfirmShowPassword}
                                >
                                    {confirmShowPassword ? (
                                        <NoShowPassword wh={18} color={'#666666'} strokeW={2}/>
                                    ) : (
                                        <ShowPassword wh={18} color={'#666666'} strokeW={2}/>
                                    )}
                                </div>
                            </div>
                        </label>
                        <p
                            className={
                                userData.confirmPassword && !validMatch
                                    ? "text-red-500 flex flex-row gap-4 items-center m-2"
                                    : "hidden"
                            }
                        >
                            <span>
                                <Information wh={15} color={'#FF9600'}/>
                            </span>
                            Vos mots de passe ne correspondent pas.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;
