
import UseContext from "hooks/useContext";


const useModal = () => {
    const {modalShow,setModalShow,setModalType}=UseContext()


    function toggle(v) {
        setModalType(v)
        setModalShow(!modalShow)
    }

    return {
        toggle
    };
};

export default useModal;