import React, {useContext, useEffect, useState} from "react";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {JobbizLogo, MenuBar} from "assets/icons";
import Button from "components/button";
import UseContext from "hooks/useContext";
import Banner from "../banner/banner";


const NavbarAuth = () => {
    const {typeNav}=UseContext()
    const [menu, setMenu] = useState(false);

    const handleMenu = () => {
        setMenu(!menu);
    };


    return (
        <>
            <header className="h-24">
                <nav
                    className="
          flex flex-wrap
          items-center
          justify-between
          w-full
          py-4
          md:py-0
          px-4
          text-lg text-gray-700
          bg-white
        "
                >
                    <div>
                        <JobbizLogo wh={120}/>
                    </div>
                    <div onClick={handleMenu}>
                        <MenuBar/>
                    </div>

                    <div
                        className={`${
                            menu ? "" : "hidden"
                        } w-full md:flex md:items-center md:w-auto z-30 bg-white mt-5`}
                    >
                        <ul className={`
                         ${menu ? "  flex flex-col gap-4 py-4 items-left justify-left" : ""}
                            text-base 
                            font-medium
                            md:flex
                            md:gap-3
                            md:items-center
                            md:py-2`}
                        >
                            <li>
                                <NavLink
                                    className="md:p-4 py-9  font-medium text-[#374151] "
                                    to="/help"
                                >
                                    Aide
                                </NavLink>
                            </li>
                            <li>
                                {typeNav === 2 ?
                                    <NavLink
                                        className="md:p-4 py-2 font-medium  text-[#374151]"
                                        to="/login/interimaire"
                                    >
                                        Je suis intérimaire
                                    </NavLink>
                                    :
                                    <NavLink
                                        className="md:p-4 py-2 font-medium  text-[#374151]"
                                        to="/login/entreprise"
                                    >
                                        Je suis une entreprise
                                    </NavLink>
                                }
                            </li>
                            <li>
                                <NavLink
                                    className="md:p-4 py-2 font-medium  text-[#374151]"
                                    to={typeNav === 2 ? '/login/entreprise':'/login/interimaire'}
                                >
                                    Se connecter
                                </NavLink>
                            </li>
                            <li>
                                {
                                    typeNav === 2 ?
                                        <a href="https://www.jobbiz.fr/demo-entreprise">
                                            <Button size={"LG"}>Demander une démo</Button>
                                        </a>
                                        :
                                        <NavLink to="/register/interimaire">
                                            <Button size={"LG"}>S'inscrire</Button>
                                        </NavLink>
                                }
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        </>
    );
};

export default NavbarAuth;
