function Language () {

    // languages available
    this.languages = new Map();

    // current language
    this.current = null;

    this.loadLanguage = function (pLang) {
        // language key
        const k = pLang.name;

        // language exists ?
        const l = this.languages.get(k)
        if (l) return

        // map to store keys/values
        const data = pLang.data;
        const map = new Map()

        for (let i in data) {
            map.set(i, data[i])
        }
        this.languages.set(k, map)
    };

    this.setLanguage = function (pLang) {
        this.current = this.languages.get(pLang)
    };

    this.getValue = function (pKey, pDefault="") {
        if (!pKey || !this.current || pKey.length===0) return pDefault;
        let obj = this.current;
        const path =  pKey.split(".")
        for (let k of path) {
            if (obj instanceof Map) {
                if (!obj.has(k)) return pDefault
                obj = obj.get(k)
            } else {
                if (!obj.hasOwnProperty(k)) return pDefault
                obj = obj[k]
            }
        }
        return obj
        // return this.current.has(pKey) ? this.current.get(pKey) : pDefault;
    }
}


export {Language};