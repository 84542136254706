import UseContext from "../../../hooks/useContext";
import useModal from "../../modal/useModal";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import Button from "../../button";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";

const TokenRegister = ({userData}) => {
    const [token, setToken] = useState('')
    const {toggle}=useModal()
    const [validToken, setValidToken] = useState(false)
    const [waitValidationCode, setWaitValidationCode] = useState(0)
    const navigate = useNavigate()

    const handleMail = async (e) => {
        e.preventDefault()
        axios({
            method:'post',
            url:`${process.env.REACT_APP_API_URL}resend/tokenMail`,
            data:{
                email: userData?.email
            }
        })
            .then((res)=>{
                setWaitValidationCode(30)
            })
            .catch((err)=>{
                if (err?.response?.data?.timeout) setWaitValidationCode(err?.response?.data?.timeout)
            })

    }

    useEffect(() => {
        //timeout for resend mail when the time is waitValidationCode
        if (waitValidationCode > 0) {
            setTimeout(() => {
                setWaitValidationCode(waitValidationCode - 1)
            }, 1000)
        }
    }, [waitValidationCode]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (token === '') toast.error('Veuillez entrer le code de vérification')
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}resend/token`,
            data: {
                email: userData?.email,
                token
            }
        })
            .then((res) => {
                if (res.data.error) {
                    setValidToken(true)
                } else {
                    setValidToken(false)
                    navigate('/login/interimaire')
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div className="rounded-md -space-y-px">
            <div className="flex flex-col gap-3">
                <div className="">
                    <input
                        className='w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg '
                        name='token'
                        onChange={(e) => setToken(e.target.value)}
                        value={token}
                    />
                </div>
                <div className='flex justify-between items-center mt-4 ft-xs'>
                    {waitValidationCode > 0 ? (
                        <>
                            <div className={'text-gray-500 flex flex-col gap-1 text-center'}>
                                <div>Renvoyer le code de vérification dans {waitValidationCode} secondes.</div>
                                <div>Pensez à vérifier vos spams/indésirables.</div>
                            </div>
                        </>
                    ) : (
                        <div className={'cursor-pointer text-gray-500'} onClick={handleMail}>Renvoyer le code</div>
                    )}
                    <Button onClick={handleSubmit} size={'LG'}>Confirmer</Button>
                </div>
            </div>
        </div>
    )
}

export default TokenRegister