import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
//IMPORT COMPONENT
import NameInfos from "./Forms/nameInfos";
import Register from "./Forms/Register";
import NavbarAuth from "components/navBar";
import {ArrowLeft} from "assets/icons";
import Button from "components/button";
import useModal from "components/modal/useModal";
import UseContext from "hooks/useContext";
import {toast} from "react-toastify";
import AddressInfos from "./Forms/addressInfos";
import PreferenceQualification from "./Forms/preferenceQualification";
import TokenRegister from "./Forms/tokenRegister";

const FormRegister = () => {
    const navigate = useNavigate();
    const {setEmail}=UseContext()
    const {toggle} = useModal()
    const [page, setPage] = useState(0);
    const [userData, setUserData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        nom: "",
        prenom: "",
        phone: "",
        birthday: "",
        address: {},
        preferenceQualifications: [],
    });
    const [validFormRegister, setValidFormRegister] = useState(false);
    const [validFormName, setValidFormName] = useState(false);
    const [validFormAddress, setValidFormAddress] = useState(false);
    const [validFormPreferenceQualification, setValidFormPreferenceQualification] = useState(false);
    const formTitles = ["S’inscrire sur Jobbiz", "Quel est votre nom ?", "Où vous situez-vous ?", "Quels postes recherchez-vous ?", "Vérification par mail"];
    const formDescriptions = [
        "En m'inscrivant, j'accepte les conditions et la politique de confidentialité de Jobbiz.",
        "Nous aimerions en savoir un peu plus sur vous !",
        "Nous aimerions en savoir un peu plus sur vous !",
        null,
        "Nous vous avons envoyé un mot de passe unique à votre e-mail, tapez votre code ici.",
    ];

    const PageDisplay = () => {
        if (page === 0) {
            return <Register userData={userData} setUserData={setUserData} setValidFormRegister={setValidFormRegister}/>;
        } else if (page === 1) {
            return <NameInfos userData={userData} setUserData={setUserData} setValidFormName={setValidFormName}/>;
        } else if (page === 2) {
            return <AddressInfos userData={userData} setUserData={setUserData} setValidFormAddress={setValidFormAddress}/>
        } else if (page === 3) {
            return <PreferenceQualification userData={userData} setUserData={setUserData} setValidFormPreferenceQualification={setValidFormPreferenceQualification}/>
        } else if (page === 4) {
            return <TokenRegister userData={userData}/>
        }
    };


    const handleNextForm = (e) => {
        e.preventDefault()
        if (validFormRegister) {
            if (page !== formTitles.length - 1) {
                let isStepValid = false;

                if (page === 0) {
                    //check if password have this conditions : 1 uppercase, 1 lowercase, 1 number, 1 special character and 8 characters minimum and 40 maximum
                    if (
                        validFormRegister &&
                        userData.password === userData.confirmPassword &&
                        userData.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[a-zA-Z0-9\S]{8,40}$/) &&
                        userData.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
                    ) {
                        isStepValid = true;
                    }
                } else if (page === 1) {
                    if (validFormName) {
                        isStepValid = true;
                    }
                } else if (page === 2) {
                    if (validFormAddress) {
                        isStepValid = true;
                    }
                }

                if (isStepValid) setPage((currPage) => currPage + 1);
            }
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validFormRegister && validFormName) {
            setEmail(userData.email)
            userData.birthday = userData.birthday.split("-").reverse().join("-");

            let ip = null
            let browser = null
            let os = null
            let device = null
            let country = null
            let city = null
            let region = null
            let latitude = null
            let longitude = null
            let timezone = null
            //get ip address and other info with https://ipinfo.io/json
            const ipInfos = await axios.get(`https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`).then(res => res.data).catch(err => null)

            if(ipInfos){
                ip = ipInfos.ip
                country = ipInfos.country
                city = ipInfos.city
                region = ipInfos.region
                latitude = ipInfos.loc.split(',')[0]
                longitude = ipInfos.loc.split(',')[1]
                timezone = ipInfos.timezone
            }

            //get browser, os, device
            let OSName = "Unknown OS";
            if (navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
            if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
            if (navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
            if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux";
            if (navigator.appVersion.indexOf("Android") !== -1) OSName = "Android";
            if (navigator.appVersion.indexOf("like Mac") !== -1) OSName = "iOS";

            os = OSName

            let userAgent = navigator?.userAgent

            if(userAgent){
                //return browser name and version
                if (userAgent.indexOf("Chrome") !== -1) browser = "Chrome";
                else if (userAgent.indexOf("Safari") !== -1) browser = "Safari";
                else if (userAgent.indexOf("Firefox") !== -1) browser = "Firefox";
                else if (userAgent.indexOf("MSIE") !== -1) browser = "IE";
                else browser = "Unknown Browser";

                //return device name and type
                if (userAgent.indexOf("Mobile") !== -1) device = "Mobile";
                else if (userAgent.indexOf("Tablet") !== -1) device = "Tablet";
                else device = "Desktop";
            }

            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}user/register`,
                data: {
                    firstname: userData.prenom,
                    lastname: userData.nom,
                    email: userData.email,
                    password: userData.password,
                    birthday: userData.birthday,
                    phone: userData.phone,
                    address: userData.address,
                    preferenceQualifications: userData.preferenceQualifications,
                    ip,
                    browser,
                    os,
                    device,
                    country,
                    city,
                    region,
                    latitude,
                    longitude,
                    timezone
                }
            })
            .then((res) => {
                if (res.data.errors) {
                    toast.error(res.data.errors)
                } else {
                    setPage((currPage) => currPage + 1);
                }
            })
        } else {
            toast.error("Le formulaire n'est pas valide")
        }
    };
    const handleReturn = (e) => {
        setPage((currPage) => currPage - 1);
    };
    const handleNavigate = (e) => {
        navigate('/login/interimaire')
    }
    return (
        <>
            <div className="flex h-screen bg-white">
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    <NavbarAuth/>
                    <main className="flex justify-center bg-white">
                        {(page > 0 && page < 4) ? (
                            <div className="inline-flex  gap-3 items-center justify-center absolute top-16 left-0 m-4 cursor-pointer"
                                 onClick={handleReturn}
                            >
                                <ArrowLeft w={15} h={12} color={'#111827'} />
                                <p className="text-gray-500 text-sm">Retour</p>
                            </div>
                        ) : (
                            ""
                        )}
                        <div className="py-12 px-5 mm:px-6 lg:px-8 w-full flex flex-col">
                            <div className="max-w-[600px] space-y-8 rounded-lg border mx-auto w-full">
                                <div className={'p-10 flex flex-col gap-4 w-full'}>
                                    {(page && page < 4)> 0 && (
                                        <div className={'flex gap-2 w-full'}>
                                            <div className={`h-1 rounded-lg w-full ${page >= 1 ? 'bg-yellow-500' : 'bg-yellow-300'}`}></div>
                                            <div className={`h-1 rounded-lg w-full ${page >= 2 ? 'bg-yellow-500' : 'bg-yellow-300'}`}></div>
                                            <div className={`h-1 rounded-lg w-full ${page >= 3 ? 'bg-yellow-500' : 'bg-yellow-300'}`}></div>
                                        </div>
                                    )}
                                    <div className={'text-center flex flex-col gap-3'}>
                                        <h1 className="mt-6 text-3xl font-medium text-[#111827]">
                                            {formTitles[page]}
                                        </h1>
                                        <div className={'text-gray-500'}>
                                            {formDescriptions[page]}
                                        </div>
                                    </div>
                                    <div className="rounded bg-white">
                                        <form className="space-y-4">
                                            {PageDisplay()}

                                            <div className={'flex justify-end'}>
                                                {page === 0 &&  (
                                                    <>
                                                        {validFormRegister ? (
                                                            <Button size={"FULL"} onClick={(e)  => {handleNextForm(e)}}>S'inscrire</Button>
                                                        ) : (
                                                            <Button size={"FULL"} color={'DISABLED'}>S'inscrire</Button>
                                                        )}
                                                    </>
                                                )}
                                                {page === 1 && (
                                                    <div className={'flex gap-2'}>
                                                        <Button size={'LG'} color={'SECONDARY'} onClick={(e)  => {handleReturn()}}>Retour</Button>
                                                        {validFormName ? (
                                                            <Button size={'LG'} onClick={(e)  => {handleNextForm(e)}}>Suivant</Button>
                                                        ) : (
                                                            <Button size={'LG'} color={'DISABLED'}>Suivant</Button>
                                                        )}
                                                    </div>
                                                )}
                                                {page === 2 && (
                                                    <div className={'flex gap-2'}>
                                                        <Button size={'LG'} color={'SECONDARY'} onClick={(e)  => {handleReturn()}}>Retour</Button>
                                                        {validFormAddress ? (
                                                            <Button size={'LG'} onClick={(e)  => {handleNextForm(e)}}>Suivant</Button>
                                                        ) : (
                                                            <Button size={'LG'} color={'DEFAULT'} >Suivant</Button>
                                                        )}
                                                    </div>
                                                )}
                                                {page === 3 && (
                                                    <div className={'flex gap-2'}>
                                                        <Button size={'LG'} color={'SECONDARY'} onClick={(e)  => {handleReturn()}}>Retour</Button>
                                                        {validFormPreferenceQualification ? (
                                                            <Button size={'LG'} onClick={(e)  => {handleSubmit(e)}}>Confirmer ({userData.preferenceQualifications.length}/5)</Button>
                                                        ) : (
                                                            <Button size={'LG'} color={'DISABLED'}>Confirmer ({userData.preferenceQualifications.length}/5)</Button>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            {page === 0 && (
                                                <div className="flex justify-center">
                                                  <span
                                                      onClick={handleNavigate}
                                                      className="ft-sm font-medium  text-gray-500 cursor-pointer"
                                                  >
                                                    Vous avez déjà un compte ?
                                                  </span>
                                                </div>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
};

export default FormRegister;
