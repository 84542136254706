import React from 'react'

const ModalBody = (props) => {
    return (
        <div className="relative p-6 flex-auto">

            {props.children}
        </div>
    )
}

export default ModalBody