import {ContextProvider} from "context/Context";
import Routes from "routes";
import Modal from 'components/modal/portal'
function App() {
  return (
      <ContextProvider>
          <Modal/>
          <Routes/>
      </ContextProvider>
  );
}

export default App;
