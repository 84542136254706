// *********** REGEX ***********
// Regex Name
module.exports.NAME_REGEX = /^[\p{L}'][ \p{L}'-]*[ \p{L}]$/u;
//Regex Verify
module.exports.EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
// const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/;
module.exports.PWD_REGEX = /^(?=.{8,40}$)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).*$/;

module.exports.MESSAGE_REGEX =/^[a-zA-Z\d?$@#()'!,+\-=_:.&€£*%\s]+$/

// *********** Function ***********
// Calcul de l'age selon une date en format string
module.exports.getAge = (dateString) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

module.exports.checkPassword = (data) => {
    //check if password have 8 characters adn 40 max, 1 lowercase, 1 uppercase, 1 number and 1 special character
    const lower = /[a-z]/g;
    const upper = /[A-Z]/g;
    const number = /[0-9]/g;
    const special = /[#$^+=!*()@%&]/g;
    const length = /^.{8,40}$/g;

    const check = {
        lower: false,
        upper: false,
        number: false,
        special: false,
        length: false
    }
    check.lower = lower.test(data);
    check.upper = upper.test(data);
    check.number = number.test(data);
    check.special = special.test(data);
    check.length = length.test(data);

    return check
}

module.exports.delay = (ms)=> new Promise(
    resolve => setTimeout(resolve,ms)
)

module.exports.getCookie=(key)=>{
        const cookie = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
        return cookie ? cookie.pop() : cookie;
}

module.exports.dateDiff =(date)=>{
    const diff={}
    const date1 = new Date(date)
    const date2 = new Date()
    let diff_temps = date2 - date1
    diff_temps =Math.floor(diff_temps/1000)
    diff.sec= diff_temps % 60
    diff_temps = Math.floor((diff_temps-diff.sec)/60)
    diff.min=diff_temps % 60
    diff_temps = Math.floor((diff_temps-diff.min)/60)
    diff.hour = diff_temps % 24
    diff.day = Math.floor((diff_temps - diff.hour) /24)
    return diff
}

module.exports.dateDiffExp=(date)=>{
    const ynew = date.end.getFullYear();
    const mnew = date.end.getMonth();
    const dnew = date.end.getDate();
    const yold = date.start.getFullYear();
    const mold = date.start.getMonth();
    const dold = date.start.getDate();
    let diff = date.end - date.start;
    if(mold > mnew) diff--;
    else
    {
        if(mold === mnew)
        {
            if(dold > dnew) diff--;
        }
    }
    return diff;
}

module.exports.statutMission=(date)=>{
    const status ={}
    const dateJ = new Date()
    const DateStart = new Date(date?.datestart)
    const DateEnd = new Date(date?.dateend)
    if(dateJ<DateStart){
       status.text='A venir'
        status.color=''
    }else if(dateJ > DateStart && dateJ <DateEnd){
        status.text='En cours'
        status.color='INFO'
    }else{
        status.text='Terminée'
        status.color='ALERT'
    }
    return status
}

module.exports.dateText=(d)=>{
    const date={}
    const month={
        '01':'Janv.',
        '02':'Fev.',
        '03':'Mars',
        '04':'Avril',
        '05':'Mai',
        '06':'Juin',
        '07':'Juil.',
        '08':'Août',
        '09':'Sept.',
        '10':'Oct.',
        '11':'Nov.',
        '12':'Dec.',
    }
    const dayS=d?.datestart.split('/')

    date.start=dayS[0]+' '+month[dayS[1]]+' '+dayS[2]
    const dayE=d?.dateend.split('/')
    date.end=dayE[0]+' '+month[dayE[1]]+' '+dayE[2]
    return date
}

module.exports.dateExp=(d)=>{
    const pD = new Date(d)
    const opt ={year: 'numeric', month: 'long' }
    return pD.toLocaleDateString('FR-fr',opt)
}

module.exports.datePicker=(d)=>{
    const pD= new Date(d)
}

module.exports.tContract=(c)=>{
    const tContract={
        1:'CDI',
        2:'CDD',
        3:'Interim',
        4:'Saisonnier',
        5:'Temps partiel'
    }
    return tContract[c]
}

module.exports.sBadge=(v)=>{
    const type={
        0:'WARNING',
        1:'VALID',
        2:'ALERT'
    }
    return type[v]
}

module.exports.tBadge=(v)=>{
    const type={
        0:'En attente',
        1:'Validée',
        2:'Refusée'
    }
    return type[v]
}

module.exports.pFormation=(f)=>{
    const type={
        'cap':'CAP ou équivalents',
        'bep':'BEP ou équivalents',
        "bac":"Bac (général, technique ou professionnel )ou équivalents",
        "bac_2":"Bac+2 (BTS, DUT ou équivalents)",
        "bac_3":"Bac+3(Licence ou équivalents)",
        "bac_4":"Bac+4 (Maitrise ou équivalents)",
        "bac_5":"Bac+5 et plus (Master ou équivalents)",
    }
    return type[f]
}

module.exports.tReplace=(t)=>{
    return t.replace(' ','_')
}

module.exports.dateToEpoch = (date) => {
    return new Date(new Date(date).toLocaleDateString("en-EN", {timeZone: "Europe/Paris"}));
}