import React from 'react';

const Modalstructure = ({isShowing, children}) => {
    return (
        <>
            {isShowing ?
                <>

                    <div
                        className="flex fixed justify-center items-center inset-0 z-50 overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
                        <div className="relative w-auto max-w-3xl my-6 mx-auto">
                            <div
                                className="relative flex flex-col  w-full tab:w-[700px] rounded-lg border-0 sh-lg bg-white outline-none focus:outline-none">
                                {children}
                            </div>
                        </div>
                    </div>
                    <div className="opacity-70 fixed inset-0 z-40 bg-gray-700"></div>
                </> : null}
        </>
    );
};

export default Modalstructure;