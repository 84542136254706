import React from 'react';

import FormRegister from "components/formRegister/formRegister";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
    return (
        <>
            <FormRegister/>
            <ToastContainer
                position="bottom-left"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                limit={2}
                pauseOnFocusLoss
                draggable
            />
        </>
    );
};

export default Register;