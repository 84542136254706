import React, {useState, useEffect} from "react";
import BirthdayDatePicker from "components/datePicker/birthdayDatePicker";
import {format} from "date-fns";
import SelectPhone from "../OptionsForm/SelectPhone";
import {dateToEpoch, getAge, NAME_REGEX} from "helper/helper";
import {Information} from "assets/icons";

const NameInfos = ({userData, setUserData, setValidFormName}) => {
    const [validName, setValidName] = useState(false);
    const [validPrenom, setValidPrenom] = useState(false);
    const [validPhone, setValidPhone] = useState(false);
    const [errMsgPhone, setErrMsgPhone] = useState(false);
    const [validBirthday, setValidBirthday] = useState(false);
    const [dateSelect, setDateSelect] = useState();
    const [validAge, setValidAge] = useState(false);

    useEffect(() => {
        setValidName(NAME_REGEX.test(userData.nom));
        setValidPrenom(NAME_REGEX.test(userData.prenom));
        if (userData.birthday) {
            let birthDate = userData.birthday;
            if(birthDate.match(/^..-..-....$/) || birthDate.match(/^..\/..\/....$/)){
                //detect if - or / in date
                if (birthDate.includes('-')) {
                    const dateParts = birthDate.split('-');
                    birthDate = dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0]
                } else {
                    const dateParts = birthDate.split('/');
                    birthDate = dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0]
                }
            }

            setDateSelect(dateToEpoch(birthDate));
        }
    }, [userData.nom, userData.prenom, userData.birthday]);

    useEffect(() => {
        if (dateSelect) {
            setValidBirthday(true);
            let calculatedAge = getAge(dateSelect);
            if (calculatedAge >= 18) {
                //format dd-mm-yyyy
                let dateFormat = format(dateSelect, 'dd-MM-yyyy')
                setUserData({...userData, birthday: dateFormat});
                setValidAge(true);
            } else {
                setValidAge(false);
            }
        }
    }, [dateSelect]);

    useEffect(() => {
        if(validPrenom && validName && validBirthday && validAge) setValidFormName(true);
        else setValidFormName(false);
    }, [validPrenom, validName, validBirthday, validAge]);

    return (
        <>
            <div className="rounded-md  -space-y-px">
                <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-6">
                        <label className="block">
                            <div className={'ft-sm font-medium text-gray-500'}>
                                Nom <span className={'text-red-500'}>*</span>
                            </div>
                            <div className="relative text-gray-500">
                                <input
                                    name="name"
                                    value={userData.nom}
                                    onChange={(e) => {
                                        setUserData({
                                            ...userData,
                                            nom: e.target.value.toUpperCase(),
                                        });
                                    }}
                                    className="w-full h-10 pl-3 pr-8 ft-b placeholder-gray-600 border rounded-lg "
                                    type="text"
                                    required
                                />
                            </div>
                        </label>
                        <p
                            className={
                                userData.nom && !validName
                                    ? "text-yellow-500 flex flex-row gap-4 items-center"
                                    : "hidden"
                            }
                        >
                            <span>
                                <Information wh={14} color={"#FF9600"}/>
                            </span>
                            Veuillez indiquer un nom valide.
                        </p>
                    </div>
                    <div className="col-span-6">
                        <label className="block">
                            <div className={'ft-sm font-medium text-gray-500'}>
                                Prénom <span className={'text-red-500'}>*</span>
                            </div>
                            <div className="relative text-gray-500">
                                <input
                                    name="prenom"
                                    value={userData.prenom}
                                    onChange={(e) => {
                                        setUserData({
                                            ...userData,
                                            prenom: e.target.value.replace(/\b\w/g, l => l.toUpperCase())
                                        });
                                    }}
                                    className={`w-full h-10 pl-3 pr-8 ft-b border rounded-lg  `}
                                    type="text"
                                    required
                                />
                            </div>
                        </label>
                        <p
                            className={
                                userData.prenom && !validPrenom
                                    ? "text-yellow-500 flex flex-row gap-4 items-center"
                                    : "hidden"
                            }
                        >
                            <span>
                                <Information wh={14} color={"#FF9600"}/>
                            </span>
                            Veuillez indiquer un prénom valide.
                        </p>
                    </div>
                    <div className="col-span-12">
                        <label className="block">
                            <div className={'ft-sm font-medium text-gray-500'}>
                                Numéro <span className={'text-red-500'}>*</span>
                            </div>
                            <div className="relative">
                                <SelectPhone
                                    phoneValue={userData.phone}
                                    setPhoneValue={(value) => {
                                        setUserData({...userData, phone: value});
                                    }}
                                />
                            </div>
                        </label>
                    </div>
                    <div className="col-span-12">
                        <label className="block">
                            <div className={'ft-sm font-medium text-gray-500'}>
                                Date de naissance <span className={'text-red-500'}>*</span>
                            </div>
                            <div className="relative h-auto">
                                <BirthdayDatePicker
                                    dateSelect={dateSelect}
                                    setDateSelect={setDateSelect}
                                />
                                {/* class="w-full h-10 pl-3 pr-8 text-base border rounded-lg focus:shadow-outline " */}
                            </div>
                        </label>
                        <p
                            className={
                                userData.birthday && !validBirthday
                                    ? "text-yellow-500 flex flex-row gap-4 items-center"
                                    : "hidden"
                            }
                        >
                            <span>
                                <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.00004 13.6667C3.31804 13.6667 0.333374 10.682 0.333374 7C0.333374 3.318 3.31804 0.333336 7.00004 0.333336C10.682 0.333336 13.6667 3.318 13.6667 7C13.6667 10.682 10.682 13.6667 7.00004 13.6667ZM6.33337 9V10.3333H7.66671V9H6.33337ZM6.33337 3.66667V7.66667H7.66671V3.66667H6.33337Z"
                                        fill="#FF9600"
                                    />
                                </svg>
                            </span>
                            Veuillez sélectionner une date.
                        </p>
                        <p
                            className={
                                dateSelect !== '' && !validAge
                                    ? "text-red-700 flex flex-row gap-4 items-center"
                                    : "hidden"
                            }
                        >
                            <span>
                                <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.00004 13.6667C3.31804 13.6667 0.333374 10.682 0.333374 7C0.333374 3.318 3.31804 0.333336 7.00004 0.333336C10.682 0.333336 13.6667 3.318 13.6667 7C13.6667 10.682 10.682 13.6667 7.00004 13.6667ZM6.33337 9V10.3333H7.66671V9H6.33337ZM6.33337 3.66667V7.66667H7.66671V3.66667H6.33337Z"
                                        fill="#B91C1C"
                                    />
                                </svg>
                            </span>
                            Vous devez avoir 18 ans ou plus pour valider votre inscription.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NameInfos;
