import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {AuthProvider} from "context/authProvider";
import * as Sentry from "@sentry/react";

if(process.env.REACT_APP_IS_PROD === 'true') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        authToken: process.env.REACT_APP_SENTRY_AUTH_TOKEN,
        integrations: [
            new Sentry.BrowserTracing(),
            new Sentry.Replay(),
        ],
        // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
        tracesSampleRate: 1.0,

        // Capture Replay for 10% of all sessions, plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter basename={process.env.PUBLIC}>
        <AuthProvider>
            <Routes>
                <Route path='/*' element={<App/>}/>
            </Routes>
        </AuthProvider>
    </BrowserRouter>
);

