import React from 'react';
import ReactDOM from "react-dom";
import Datamodal from "components/modal/dataModal";
import Modalstructure from "components/modal/components/structure";
import UseContext from "hooks/useContext";

const Index = () => {
    const {modalShow}=UseContext()

    if(!modalShow) return null

    return ReactDOM.createPortal(
        <Modalstructure isShowing={modalShow}>
            <Datamodal/>
        </Modalstructure>
        , document.getElementById('root-portal'));
};

export default Index;