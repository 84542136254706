import axios from "axios";

export default {
    postUserLogin: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}user/login`,
            withCredentials: true,
            data: {
                email: payload.email,
                password: payload.password,
                ip: payload.ip,
                browser: payload.browser,
                os: payload.os,
                device: payload.device,
                country: payload.country,
                city: payload.city,
                region: payload.region,
                latitude: payload.latitude,
                longitude: payload.longitude,
                timezone: payload.timezone,
            }
        })
            .then(res => {
                if (res.data.errors)
                    reject(res.data.errors)
                else
                    resolve(res.data)
            })
            .catch(err => {
               reject(err)
            })
    },
    postTokenPwdMail: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}resend/tokenPasswordMail`,
            data: {
                email: payload.email
            }
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
               reject(err)
            })
    },
    postTokenPwd: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}resend/tokenPassword`,
            data: {
                email: payload.email,
                token: payload.token
            }
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    },
    putPwdUser: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/update/password`,
            data: {
                email: payload.email,
                password: payload.password,
                token_password: payload.token_password,
                isDesktop: payload.isDesktop
            }
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    },
    putPwdUserCompany: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/update/password`,
            data: {
                id: payload.id,
                email: payload.email,
                password: payload.password,
                validation: payload.validation
            }
        })
            .then(res => {
                    resolve(res.data)
            })
            .catch(err => {
                if (reject) reject( err)
            })
    },
    putPwdUserTempWorker: (payload, resolve, reject) => {
        axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}user/update/updatePasswordMigration`,
            data: {
                id: payload.id,
                email: payload.email,
                password: payload.password,
                validation: payload.validation
            }
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                if (reject) reject( err)
            })
    },

    postRoleUser: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}user/role`,
            data: {
                token: payload.token
            }
        })
            .then(res => {
                    resolve(res.data)
            })
            .catch(err => {
            reject(1, err)
            })
    },
    getUserProfile: (payload, resolve, reject) => {
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}user/info`,
            withCredentials: true,
            data: {
                token: payload.token
            }
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getCities: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}city`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    },
    getAllQualificationsPreferences: (payload, resolve, reject) => {
        axios({
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}qualification/getAllQualificationsPreferences`,
            withCredentials: true,
        })
            .then(res => resolve(res.data))
            .catch(err => reject(err))
    }
}