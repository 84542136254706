import React, {useContext, useEffect, useState} from 'react';
import NavbarAuth from "components/navBar";
import {Information, Mail} from "assets/icons";
import {EMAIL_REGEX} from "helper/helper";
import Button from "components/button";
import useModal from "components/modal/useModal";
import ApiDatabase from 'server'
import UseContext from "hooks/useContext";


const Forgot = () => {
const {toggle}=useModal()
    const {email, setEmail} = UseContext()
    const [validEmail, setValidEmail] = useState(false)
    const [errMsg, setErrMsg] = useState(false)
    const [sucess, setSucess] = useState(false)
    const [navigating, setNavigating] = useState(false)

    useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email))
    }, [email])


    const handleSubmit = async (e) => {
        e.preventDefault()

        if (validEmail) {
            setErrMsg(false)
            ApiDatabase.postTokenPwdMail({email},
                (data)=>{
                    if (data.errors) {
                        setValidEmail(true)
                    } else {
                        setSucess(true)
                    }
                },
            (err)=>{console.log(err)})
        } else {
            setErrMsg(true)
        }
    }
    if (sucess && navigating === false) {
        setNavigating(true);
        window.setTimeout(() => {
            toggle('tok_u_tokenPwd')
        }, 3000)
    }
    return (
        <>
            <div className='flex h-screen  overflow-hidden'>
                {/*Content area*/}
                <div
                    className='relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden'>
                    {/*Navbar*/}
                    <NavbarAuth/>
                    <main
                        className='h-screen w-screen flex items-center justify-center'>
                        {/* Main Content */}
                        <div
                            className='flex items-center justify-center min-w-[300px] w-[500px]'>
                            {sucess ?
                                <div
                                    className="max-w-md w-full space-y-8">
                                    <div
                                        className="flex flex-col items-center gap-2">
                                        <h2 className="mt-6 text-center text-3xl font-medium text-[#111827]">
                                            Lien de connexion
                                        </h2>
                                        <p className="text-center">
                                            Si vous avez un compte associé à {email}, vous devriez recevoir un e-mail de réinitialisation dans les prochaines minutes.
                                        </p>
                                        <p className="text-center mt-3">
                                            Veuillez vérifier votre boîte de réception, y compris le dossier des courriers indésirables. Si vous ne recevez pas l'e-mail, veuillez vérifier que l'adresse e-mail saisie est correcte et réessayez.
                                        </p>
                                    </div>
                                </div>
                                :
                                <div
                                    className="max-w-md w-full space-y-8">
                                    <div
                                        className="flex flex-col items-center justify-center gap-2">
                                        <h2 className="mt-6  text-2xl font-medium text-[#111827]">
                                            Mot-de-passe oublié
                                        </h2>
                                        <p
                                            className={
                                                errMsg
                                                    ? "text-[red] flex flex-row gap-4 items-center"
                                                    : "hidden"
                                            }
                                        >
                                          <span>
                                            <Information wh={15}
                                                         color={'#FF9600'}/>
                                          </span>
                                            Veuillez bien remplir les
                                            champs.
                                        </p>
                                    </div>

                                    <div
                                        className="rounded bg-white  md:max-w-tab   overflow-hidden  p-2 mm:p-5 ">
                                        <form className="space-y-4">
                                            <div
                                                className="rounded-md space-y-[12px]">
                                                <div
                                                    className="grid grid-cols-12 gap-6">
                                                    <div
                                                        className="col-span-12">
                                                        <label
                                                            htmlFor="email_address"
                                                            className="relative  ft-sm font-medium  text-gray-500"
                                                        >
                                                            E-mail
                                                        </label>
                                                        <div
                                                            className="relative text-[#6B7280] flex justify-center items-center">
                                                            <input
                                                                name="email"
                                                                value={email}
                                                                onChange={(e) => {
                                                                    setEmail(e.target.value);
                                                                }}
                                                                className="w-full  h-[42px] pl-8 pr-3 text-base placeholder-gray-600 border rounded-lg "
                                                                type="email"
                                                                required
                                                            />
                                                            <div
                                                                className="absolute inset-y-0 left-0 flex items-center px-2 pointer-events-none">
                                                                <Mail
                                                                    wh={20}
                                                                    strokeW={2}
                                                                    color={'#666666'}/>
                                                            </div>
                                                        </div>
                                                        <p
                                                            className={
                                                                email && !validEmail
                                                                    ? "text-[#FF9600] flex flex-row gap-4 items-center justify-center "
                                                                    : "hidden"
                                                            }
                                                        >
                                                              <span>
                                                                  <Information
                                                                      wh={20}
                                                                      color={'#FF9600'}/>
                                                              </span>
                                                            Veuillez
                                                            indiquer
                                                            une
                                                            adresse
                                                            mail
                                                            valide.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div
                                                    className="flex justify-end items-center ">
                                                    <Button
                                                        size={'LG'}
                                                        onClick={handleSubmit}>M'envoyer
                                                        un
                                                        lien</Button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            }
                        </div>
                    </main>
                </div>
            </div>
        </>);
};

export default Forgot;