import React, {useEffect, useState} from 'react';
import NavbarAuth from "components/navBar";
import {
    Information,
    InterrogationCercle,
    NoShowPassword,
    ShowPassword,
    ValidationCercle
} from "assets/icons";
import Button from "components/button";
import {
    useNavigate
} from "react-router-dom";
import {checkPassword} from "helper/helper";
import ApiDatabase from 'server'
import apiDataBase from "server";

const ModifyCompany = () => {
    // recuperation de l'url pour avoir l'id
    const queryUrl = window.location.search
    const urlSearchParams = new URLSearchParams(queryUrl)
    const id = urlSearchParams.get('id')
    const nCompapny = urlSearchParams.get('nCompany')

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("")
    const [errMsg, setErrMsg] = useState(false);
    const [validMatch, setValidMatch] = useState(false);
    const [confirmShowPassword, setConfirmShowPassword] = useState(false);
    const navigate = useNavigate();
    const [lower, setLower] = useState(false)
    const [upper, setUpper] = useState(false)
    const [number, setNumber] = useState(false)
    const [special, setSpecial] = useState(false)
    const [length, setLength] = useState(false)
    const [valid, setValid] = useState(false)
    const [user, setUser] = useState({})
    const contentAdmin = {
        200: 'Observateur',
        210: 'Responsable',
        215: 'Directeur',
        225: 'Administrateur'
    }
    useEffect(() => {
        if (id !== '') {
            ApiDatabase.getUserProfile({token: id},
                (data) => {
                    setUser(data)
                },
                (err) => {
                    navigate('/login/entreprise')
                })
        }
    }, [id])

    const handleCheck = (e) => {
        const result = checkPassword(password)
        setLower(result.lower)
        setNumber(result.number)
        setLength(result.length)
        setUpper(result.upper)
        setSpecial(result.special)
        if (length && special && number && upper && lower) setValid(true)

    }
    // State show Password
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => setShowPassword(!showPassword);
    useEffect(() => {
        setValidMatch(password === confirmPassword);
    }, [password, confirmPassword]);

    const handleConfirmShowPassword = () => setConfirmShowPassword(!confirmShowPassword);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validMatch) {
            const validation = 2
            const email = user.email
            apiDataBase.putPwdUserCompany({id, email, password, validation},
                (data) => {
                    if (data.errors) {

                    } else {
                        navigate('/login/entreprise')
                    }
                },
                (err) => {
                    console.log(err)
                })
            setErrMsg(false);
        } else {
            setErrMsg(true);
        }
    }
    return (
        <>
            <div className="flex h-screen overflow-hidden">
                {/* Content area */}
                <div
                    className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {/* Navbar */}
                    <NavbarAuth></NavbarAuth>
                    <main
                        className="h-screen flex items-center justify-center">
                        {/* Main Content */}
                        <div
                            className=" flex items-center justify-center py-12 px-5 mm:px-6 lg:px-8">
                            <div
                                className="max-w-md w-full space-y-8 ">
                                <div
                                    className={'flex flex-col items-center justify-center'}>
                                    <h2 className="my-6 text-center text-2xl font-medium text-gray-900">
                                        Bienvenue {user.firstname} {user.lastname}
                                    </h2>
                                    <p className={'ft-sm text-gray-500 text-center'}>Vous
                                        avez été invité par <span
                                            className={'text-gray-700 font-bold '}>{nCompapny}</span> en
                                        tant que {contentAdmin[user.role]}.</p>

                                </div>
                                <div
                                    className="rounded bg-white md:max-w-tab tab:w-[30rem] overflow-hidden  p-2 mm:p-5 ">
                                    <form className="space-y-4 ">
                                        <div
                                            className="rounded-md shadow-sm space-y-4">
                                            <div
                                                className="grid grid-cols-12 gap-6">
                                                <div
                                                    className="col-span-12 mb-4">
                                                    <label
                                                        className="block ft-sm font-medium  text-gray-500">
                                                        Nouveau mot de
                                                        passe
                                                    </label>
                                                    <div
                                                        className="relative text-gray-500">
                                                        <input
                                                            name="password"
                                                            value={password}
                                                            onChange={(e) => {
                                                                setPassword(e.target.value);
                                                            }}
                                                            onKeyUp={handleCheck}
                                                            className={`w-full h-10 pl-3 pr-8 text-base border rounded-lg  `}
                                                            type={showPassword ? "text" : "password"}
                                                            required
                                                        />
                                                        <div
                                                            className="absolute inset-y-0 right-0 flex items-center px-2 cursor-pointer"
                                                            onClick={handleShowPassword}
                                                        >
                                                            {showPassword ? (
                                                                <NoShowPassword
                                                                    wh={18}
                                                                    color={'#666666'}
                                                                    strokeW={2}/>) : (
                                                                <ShowPassword
                                                                    wh={18}
                                                                    color={'#666666'}
                                                                    strokeW={2}/>)}
                                                        </div>
                                                    </div>
                                                    {password !== '' ?
                                                        <div
                                                            className='bg-gray-100 p-2 sh-sm mt-3 rounded-md'>
                                                            <p className={`flex flex-row justify-start items-center gap-3 ${valid ? 'text-green-600' : 'text-yellow-600'}`}>
                                                     <span>
                                                              {valid ?
                                                                  <Information
                                                                      wh={20}
                                                                      color={'#059669'}/> :

                                                                  <Information
                                                                      wh={20}
                                                                      color={'#FF9600'}/>}              </span>
                                                                Votre
                                                                mot
                                                                de
                                                                passe
                                                                doit
                                                                contenir
                                                                :
                                                            </p>
                                                            <ul className="relative flex flex-col gap-3 mt-2">
                                                                <li className={`${length ? 'text-gray-300' : ''} relative flex flex-row items-center list-none transition-all ft-sm gap-3 `}>
                                                                    {/*#059669 -> vert 600 #DC2626-> red 600 */}
                                                                    {length ?
                                                                        <ValidationCercle
                                                                            wh={20}
                                                                            color={'#059669'}/> :
                                                                        <InterrogationCercle
                                                                            wh={20}
                                                                            color={'#DC2626'}/>}Au
                                                                    moins
                                                                    8
                                                                    caractères
                                                                </li>
                                                                <li className={`${upper ? 'text-gray-300' : ''} relative flex flex-row list-none transition-all ft-sm gap-3`}>
                                                                    {upper ?

                                                                        <ValidationCercle
                                                                            wh={20}
                                                                            color={'#059669'}/> :
                                                                        <InterrogationCercle
                                                                            wh={20}
                                                                            color={'#DC2626'}/>}
                                                                    Au
                                                                    moins
                                                                    un
                                                                    caractère
                                                                    majuscule
                                                                </li>
                                                                <li className={`${lower ? 'text-gray-300' : ''} relative flex flex-row list-none transition-all ft-sm gap-3`}>
                                                                    {lower ?

                                                                        <ValidationCercle
                                                                            wh={20}
                                                                            color={'#059669'}/> :
                                                                        <InterrogationCercle
                                                                            wh={20}
                                                                            color={'#DC2626'}/>}
                                                                    Au
                                                                    moins
                                                                    un
                                                                    caractère
                                                                    minuscule
                                                                </li>
                                                                <li className={`${special ? 'text-gray-300' : ''} relative flex flex-row list-none transition-all ft-sm gap-3`}>
                                                                    {special ?

                                                                        <ValidationCercle
                                                                            wh={20}
                                                                            color={'#059669'}/> :
                                                                        <InterrogationCercle
                                                                            wh={20}
                                                                            color={'#DC2626'}/>}
                                                                    Au
                                                                    moins
                                                                    un
                                                                    caractère
                                                                    spécial
                                                                </li>
                                                                <li className={`${special ? 'text-gray-300' : ''} relative flex flex-row list-none transition-all ft-sm gap-3`}>
                                                                    {number ?

                                                                        <ValidationCercle
                                                                            wh={20}
                                                                            color={'#059669'}/> :
                                                                        <InterrogationCercle
                                                                            wh={20}
                                                                            color={'#DC2626'}/>}
                                                                    Au
                                                                    moins
                                                                    un
                                                                    chiffre
                                                                    (0-9)
                                                                </li>
                                                            </ul>
                                                        </div> : ''}

                                                </div>

                                            </div>
                                            <div
                                                className="col-span-12">
                                                <label
                                                    htmlFor="confirmPassword"
                                                    className="block text-sm font-medium font-Poppins text-[#6B7280]"
                                                >
                                                    Confirmation mot
                                                    de passe
                                                </label>
                                                <div
                                                    className="relative">
                                                    <input
                                                        name="confirmPassword"
                                                        onChange={(e) => {
                                                            setConfirmPassword(e.target.value);
                                                        }}
                                                        value={confirmPassword}
                                                        className="w-full h-10 pl-3 pr-8 text-base border rounded-lg focus:shadow-outline "
                                                        type={confirmShowPassword ? "text" : "password"}
                                                        required
                                                    />
                                                    <div
                                                        className="absolute inset-y-0 right-0 flex items-center px-2"
                                                        onClick={handleConfirmShowPassword}
                                                    >
                                                        {confirmShowPassword ? (
                                                            <NoShowPassword
                                                                wh={18}
                                                                color={'#666666'}
                                                                strokeW={2}/>) : (
                                                            <ShowPassword
                                                                wh={18}
                                                                color={'#666666'}
                                                                strokeW={2}/>)}
                                                    </div>
                                                </div>
                                                <p
                                                    className={confirmPassword && !validMatch ? "text-[#EF4444] flex flex-row gap-4 items-center m-2" : "hidden"}
                                                >
                                                      <span>
                                                          <Information
                                                              wh={15}
                                                              color={'#FF9600'}/>
                                                      </span>
                                                    Vos mots de passe
                                                    ne correspondent
                                                    pas.
                                                </p>
                                            </div>

                                        </div>
                                        <div
                                            className="flex  justify-center items-center gap-2">
                                            <Button size={'LG'}
                                                    onClick={handleSubmit}>
                                                Activer mon compte
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>);
};

export default ModifyCompany;