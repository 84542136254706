import {createContext, useState, useEffect} from "react";

import {LangFr} from "assets/locales/fr";
import {LangEn} from "assets/locales/en";
import {Language as LanguageManager} from "assets/locales/fLang";
import {useLocation} from "react-router-dom";

const roadContext = createContext({})

function ContextProvider({children}) {
// *******   Section Sidebar   *******
    const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [sidebarExpanded, setSidebarExpanded] = useState(
        storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
    );
// *******   Section Refresh   *******
    const [refreshUser, setRefreshUser] = useState(false)
    const refreshLoadUser=()=>{
        setRefreshUser(!refreshUser)
    }
    const [refreshWorker, setRefreshWorker] = useState(false)
    const refreshLoadWorker=()=>{
        setRefreshWorker(!refreshWorker)
    }
// *******   Section Language   *******
//    instancie le manager
    const language = new LanguageManager()
//    charge les fichiers de langues (ils seront chargés qu'une fois)
    language.loadLanguage(LangFr)
    language.loadLanguage(LangEn)
//    spécifie la langue en cours
    language.setLanguage('fr')

// *******   Section Modal   *******
    const [modalShow, setModalShow] = useState(false)
    const [modalType, setModalType] = useState('')

// *******   Section URL   *******
    const route = useLocation()
    const {pathname}=route
    //1 - interimaire 2- entreprise
    const [typeNav, setTypeNav] = useState(1)
    const [email,setEmail]=useState('')
    const [name,setName]=useState({
        firstname:'',
        lastname:''
    })
    useEffect(()=>{
            if(pathname.includes('entreprise')) setTypeNav(2)
            else setTypeNav(1)
        }
        ,[pathname])

    const [auth,setAuth]=useState({})
    const updateUser = (pToken = '-1',pRole) => {
        if (pToken !== '-1') {
            localStorage.setItem('xsrfToken', pToken)
            localStorage.setItem('rUser',pRole)
        }
    }

// *******   Section OBJ   *******
    const [expID, setExpID] = useState('')
    const [objID, setObjID] = useState('')
    const [objType,setObjType]=useState('')

    return (
        <roadContext.Provider value={{
            language,
            modalShow, setModalShow, modalType, setModalType,
            typeNav,
            email,setEmail,
            name,setName,
            updateUser,
            auth,setAuth,
            refreshUser, setRefreshUser,refreshLoadUser,
            refreshWorker, setRefreshWorker,refreshLoadWorker,
            sidebarOpen, setSidebarOpen,
            sidebarExpanded, setSidebarExpanded,
            expID, setExpID,
            objID, setObjID,objType,setObjType,
        }}>
            {children}
        </roadContext.Provider>
    )
}

export {ContextProvider, roadContext}