import React, {useEffect, useState} from "react";
import "react-phone-number-input/style.css";
import PhoneInput, {formatPhoneNumberIntl, isValidPhoneNumber} from "react-phone-number-input";
import {Information} from "assets/icons";
import axios from "axios";


const SelectPhone = ({phoneValue, setPhoneValue}) => {
  const [value, setValue] = useState('');
  const [validPhone, setValidPhone] = useState(false);
  const [country, setCountry] = useState('MQ');

  useEffect(() => {
    if(validPhone){
      setPhoneValue(formatPhoneNumberIntl(`${value}`).replace(/\s+/g, ''))
    }
  }, [validPhone])

  useEffect(() => {
    setValidPhone(isValidPhoneNumber(`${value}`));
  }, [value]);

  useEffect(() => {
    if (phoneValue?.length > 0) {
      setValue(formatPhoneNumberIntl(phoneValue));
    }
  }, [phoneValue])

  const getCountry = async () => {
    const ipInfos = await axios.get(`https://ipinfo.io/json?token=${process.env.REACT_APP_IPINFO_TOKEN}`).then(res => res.data).catch(err => null)
    if(ipInfos){
      setCountry(ipInfos.country ?? 'MQ')
    }
  }

  useEffect(() => {
    getCountry()
  }, []);

  return (<>
    <PhoneInput
        className="w-full h-10 pl-3 text-base border rounded-lg"
        placeholder="Indiquer votre numéro"
        defaultCountry={country}
        value={value}
        onChange={setValue}
    />
    {!validPhone && (
        <p className={value?.toString().length > 0 && !validPhone ? "text-yellow-500 flex flex-row gap-4 items-center" : "hidden"}>
          <span>
            <Information wh={14} color={"#FF9600"}/>
          </span>
          Numéro invalide
        </p>
    )}
  </>);
};

export default SelectPhone;
