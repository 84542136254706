import React,{useContext} from "react";
import TokenPwd from "components/modal/dataModal/data/ModalToken/tokenPwd";
import ModalToken from "components/modal/dataModal/data/ModalToken/token";
import UseContext from "hooks/useContext";

import ModalTokenPassword
    from "components/modal/dataModal/data/ModalToken/tokenPwd";
import Tokenemail
    from "components/modal/dataModal/data/ModalToken/tokenemail";
//Import Modal



// Function
// nomination des modales
//Premiere partie:
// j-> justificatif *** msg->messagerie *** ntf->notification *** par-> parametres ***
// sf-> savoir faire *** tok-> token *** log->logout *** doc->documents
//Seconde partie:
//u-Update *** c->create *** d->delete ***g->get
//Troisieme partie:
//nom de la modale
const dataModal=new Map()

dataModal.set('tok_u_token',<ModalToken/>)
dataModal.set('tok_u_tokenPwd',<ModalTokenPassword/>)
dataModal.set('tok_u_tokenEmail',<Tokenemail/>)

const Datamodal = () => {
    const {modalType}=UseContext()
    return (
        <>
            {dataModal.get(modalType)}
        </>
    );
};

export default Datamodal;